<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin chi phí ngành hàng phụ kiện'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          id="input-group-name"
                          label="Tên chi phí"
                          label-for="input-name"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-input
                            size="sm"
                            id="input-name"
                            v-model="$v.mainModel.name.$model"
                            placeholder="Nhập tên"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group id="input-3" label="Tổng tiền:">
                          <b-form-input
                            size="sm"
                            id="input-totalAmount"
                            type="text"
                            v-model="mainModel.totalAmount"
                            placeholder="Nhập tổng tiền"
                            class="text-right"
                            v-mask="mask"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group id="input-3" label="Loại:">
                          <b-form-select
                            id="input-1"
                            v-model="selectedType"
                            :options="listType"
                            size="sm"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          id="input-group-start-date"
                          label-align-md="left"
                          required
                          class="required-control"
                          label="Tháng"
                        >
                          <!-- <label for="input-start-date">Ngày bắt đầu:</label> -->
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.month"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Ghi chú">
                          <b-form-textarea
                            id="input-description"
                            v-model="mainModel.note"
                            placeholder="Nhập ghi chú"
                            rows="5"
                            :max-length="255"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <h4>Danh sách chi phí</h4>
                <div class="separator separator-dashed my-5"></div>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm nhóm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          @selected="onSelectedProductFor"
                          :limit="10"
                          @input="onInputChangeProductFor"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên nhóm sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.name }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2"></b-col>
                      <b-col md="2"></b-col>
                      <!-- <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập nhóm SP từ excel
                          </button>
                        </div>
                      </b-col> -->
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="6" md="6" class="pr-0">
                    <div>
                      <b-table
                        class="table-bordered table-hover"
                        :fields="fields"
                        :items="mainModel.details"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(value)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.value"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlert(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} nhóm sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(amount1)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.amount1)"></span>
                    </div>
                  </template>
                  <template v-slot:cell(amount2)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.amount2)"></span>
                    </div>
                  </template>
                  <template v-slot:cell(amountOperator1)="row">
                    <span v-if="row.item.amountOperator1 === 1">--</span>
                    <span v-if="row.item.amountOperator1 === 2">&lt;</span>
                    <span v-else-if="row.item.amountOperator1 === 3">&gt;</span>
                    <span v-if="row.item.amountOperator1 === 4">&le;</span>
                    <span v-else-if="row.item.amountOperator1 === 5">&ge;</span>
                  </template>
                  <template v-slot:cell(amountOperator2)="row">
                    <span v-if="row.item.amountOperator1 === 1">--</span>
                    <span v-if="row.item.amountOperator1 === 2">&lt;</span>
                    <span v-else-if="row.item.amountOperator1 === 3">&gt;</span>
                    <span v-if="row.item.amountOperator1 === 4">&le;</span>
                    <span v-else-if="row.item.amountOperator1 === 5">&ge;</span>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <!-- </b-col> -->
                <!-- <b-col lg="2" class="pb-2"> -->
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_REWARD_PROGRAM,
  URL_IMPORT_EMPLOYEE_REWARD,
} from './../../../utils/constants';
import xlsx from 'xlsx';
import { v4 } from 'uuid';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: {
        date: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      mainModel: {
        id: 0,
        name: null,
        note: null,
        month: new Date(),
        totalAmount: 0,
        details: [],
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '60%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'value',
          label: 'Tỷ lệ (%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProductAccessory: '',
      accessoryItems: [],
      searchProductFor: '',
      categories: '',
      isSearching: false,
      importItems: [],
      file: null,
      fileER: null,
      excellist: [],
      excellistER: [],
      fieldImport: [
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount1',
          label: 'Giá trị 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amountOperator1',
          label: 'Toán tử 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'dt',
          label: 'DT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amountOperator2',
          label: 'Toán tử 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'amount2',
          label: 'Giá trị 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_REWARD_PROGRAM,
      rewardProCateName: '',
      rewardProCateId: null,
      searchJobTitle: '',
      visibleSearch: false,
      urlExcelER: URL_IMPORT_EMPLOYEE_REWARD,
      listType: [
        {
          id: 1,
          name: 'Chi phí',
        },
        {
          id: 2,
          name: 'Chiết khấu',
        },
      ],
      selectedType: 1,
    };
  },
  validations: {
    mainModel: {
      month: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chi phí ngành hàng phụ kiện',
        route: '/reward/assessory-industry-expense',
      },
      {
        title: 'Thêm mới chi phí ngành hàng phụ kiện',
      },
    ]);
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
  },
  created() {},
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.count == item.count;
            }
          );
          this.mainModel.details.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    showDeleteJobTitleAlert: function (item) {
      this.listRewardRate = _.reject(this.listRewardRate, (element) => {
        return element.jobTitleId == item.jobTitleId;
      });
      for (let index = 0; index < this.listJobTitle.length; index++) {
        const element = this.listJobTitle[index];
        if (element.id === item.jobTitleId) {
          this.optionsJobTitle[0].data.push(element);
          this.optionsJobTitle[0].data.sort(this.sortById);
          this.filteredOptionsJobTitle = [
            {
              data: this.optionsJobTitle[0].data,
            },
          ];

          break;
        }
      }
    },
    sortById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.accessoryItems.length; i++) {
        if (this.accessoryItems[i].productId === productId) {
          this.accessoryItems.splice(i, 1);
        }
      }
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          const products = data.data;
          this.listProduct = products;
          products.map((element) => {
            this.optionsProductFor[0].data.push(element);
          });
          this.filteredOptionsProductFor = [
            {
              data: this.optionsProductFor[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProductFor) {
            let nextTextSearch = this.searchProductFor;
            this.fetchProductFor(nextTextSearch);
          }
        }
      );
    },
    newId() {
      return v4();
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let item = {
        id: this.newId(),
        rewardProgramCategoryId: option.item.id,
        name: option.item.name,
        value: 0,
      };
      this.mainModel.details.push(item);
      this.mainModel.details.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onBack: function () {
      history.back();
    },
    onSubmit: function () {
      //format unmask price
      this.mainModel.details.forEach((element) => {
        element.value = unMaskPrice(element.value);
      });
      this.mainModel.month = moment(this.mainModel.month, 'MM/yyyy').toDate();
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        return;
      }
      this.mainModel.month = this.mainModel.month
        ? moment(this.mainModel.month, 'MM/YYYY').valueOf()
        : null;
      let data = {
        name: this.mainModel.name,
        note: this.mainModel.note,
        month: this.mainModel.month,
        totalAmount: unMaskPrice(this.mainModel.totalAmount),
        listDetail: this.mainModel.details,
        type: this.selectedType,
      };
      ApiService.post('assessoryIndustryExpense', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              name: element['Tên nhóm'] ? element['Tên nhóm'] : '',
              amount1: element['Giá trị 1'] ? element['Giá trị 1'] : 0,
              amountOperator1: this.convertOperatorType(element['Toán tử 1']),
              dt: 'DT',
              amount2: element['Giá trị 2'] ? element['Giá trị 2'] : 0,
              amountOperator2: this.convertOperatorType(element['Toán tử 2']),
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    selectFileER(event) {
      const files = event.target.files[0];
      this.fileER = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileER = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistER = excellist;
          this.excellistER.map((element) => {
            let data = {
              name: element['Chức vụ'] ? element['Chức vụ'] : '',
              jobTitleId: this.getJobTitleIdByName(element['Chức vụ']),
              point: element['Điểm thưởng'] ? element['Điểm thưởng'] : 0,
            };
            this.listRewardRate.push(data);
            this.optionsJobTitle[0].data = _.reject(
              this.optionsJobTitle[0].data,
              (item) => {
                return item.id == data.jobTitleId;
              }
            );
            this.filteredOptionsJobTitle = [
              {
                data: this.optionsJobTitle[0].data,
              },
            ];
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('rewardProgram/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.details.push(element);
            });
            this.mainModel.details.forEach((element, index) => {
              element.count = index + 1;
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
</style>
